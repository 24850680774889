import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Redirect, withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { Box } from '@material-ui/core';
import {
  AuthenticationContentWrapper,
  Error,
  FormattedMessage,
  IconClose,
  InlineTextButton,
  Modal,
  ModalParagraph,
  ModalTitle,
  NamedLink,
  NamedRedirect,
  PrivacyPolicy,
  TermsOfService,
  TypographyWrapper,
} from '../../components';
import AuthenticationForm from '../../components/AuthenticationForm/AuthenticationForm';
import { authenticationInProgress } from '../../ducks/Auth.duck';
import { manageDisableScrolling } from '../../ducks/UI.duck';
import { sendVerificationEmail } from '../../ducks/user.duck';
import { ensureCurrentUser } from '../../util/data';
import { isTooManyEmailVerificationRequestsError } from '../../util/errors';
import { useShopConfig } from '../../hooks/shopConfig';
import { defaultTreetStyles } from '../../shopConfig/config';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import css from './AuthenticationPage.module.css';
import { TypographyFormat } from '../../components/TypographyWrapper/TypographyWrapper';

export const AuthenticationPageComponent = (props) => {
  const [tosModalOpen, setTosModalOpen] = useState(false);
  const [privacyModalOpen, setPrivacyModalOpen] = useState(false);

  const { treetShopName, customComponents } = useShopConfig();
  const {
    currentUser,
    intl,
    isAuthenticated,
    location,
    tab,
    sendVerificationEmailInProgress,
    sendVerificationEmailError,
    onResendVerificationEmail,
    onManageDisableScrolling,
  } = props;

  const isLogin = tab === 'login';
  const from = location.state && location.state.from ? location.state.from : null;

  const user = ensureCurrentUser(currentUser);
  const currentUserLoaded = !!user.id;

  // We only want to show the email verification dialog in the signup
  // tab if the user isn't being redirected somewhere else
  // (i.e. `from` is present). We must also check the `emailVerified`
  // flag only when the current user is fully loaded.
  const showEmailVerification = !isLogin && currentUserLoaded && !user.attributes.emailVerified;

  // Already authenticated, redirect away from auth page
  if (isAuthenticated && from) {
    return <Redirect to={from} />;
  }

  if (isAuthenticated && currentUserLoaded && !showEmailVerification) {
    return <NamedRedirect name="LandingPage" />;
  }

  const fromState = { state: from ? { from } : null };

  // depending on where they were redirected from, show a banner with a reason for why they
  // must sign up or log in
  const redirectFromPathToBannerText = [
    {
      path: '/l/new',
      text: 'Please sign up or log in to create a listing.',
    },
    {
      path: '/checkout',
      text: 'Please sign up or log in to complete your purchase.',
    },
    {
      path: '/verify-email',
      text: 'Please log in to verify your email.',
    },
    {
      path: '/notification-settings',
      text: 'Please sign up or log in to manage your notification settings.',
    },
  ];

  // finds what the banner text using text match
  const bannerText = from
    ? redirectFromPathToBannerText.find(({ path }) => from.includes(path))?.text
    : null;

  const name = user.attributes.profile.firstName;
  const email = <span className={css.email}>{user.attributes.email}</span>;

  const resendEmailLink = (
    <InlineTextButton rootClassName={css.modalHelperLink} onClick={onResendVerificationEmail}>
      <TypographyWrapper
        variant="body2"
        typographyOverrides={{ style: { color: defaultTreetStyles.gray80 }, display: 'inline' }}
        format={TypographyFormat.HoverUnderlined}
      >
        <FormattedMessage id="AuthenticationPage.resendEmailLinkText" />
      </TypographyWrapper>
    </InlineTextButton>
  );
  const fixEmailLink = (
    <NamedLink className={css.modalHelperLink} name="ContactDetailsPage">
      <TypographyWrapper
        variant="body2"
        typographyOverrides={{ style: { color: defaultTreetStyles.gray80 }, display: 'inline' }}
        format={TypographyFormat.HoverUnderlined}
      >
        <FormattedMessage id="AuthenticationPage.fixEmailLinkText" />
      </TypographyWrapper>
    </NamedLink>
  );

  const resendErrorTranslationId = isTooManyEmailVerificationRequestsError(
    sendVerificationEmailError
  )
    ? 'AuthenticationPage.resendFailedTooManyRequests'
    : 'AuthenticationPage.resendFailed';
  const resendErrorMessage = sendVerificationEmailError ? (
    <Box mt={3}>
      <Error>
        <FormattedMessage id={resendErrorTranslationId} />
      </Error>
    </Box>
  ) : null;

  const emailVerificationContent = (
    <>
      <NamedLink className={css.verifyClose} name="ProfileSettingsPage">
        <IconClose />
      </NamedLink>
      <ModalTitle>
        <FormattedMessage id="AuthenticationPage.verifyEmailTitle" values={{ name }} />
      </ModalTitle>
      <ModalParagraph>
        <FormattedMessage id="AuthenticationPage.verifyEmailText" values={{ email }} />
      </ModalParagraph>
      {resendErrorMessage}

      <div className={css.bottomWrapper}>
        <p className={css.modalHelperText}>
          <TypographyWrapper
            variant="body2"
            typographyOverrides={{ style: { color: defaultTreetStyles.gray60 } }}
          >
            {sendVerificationEmailInProgress ? (
              <FormattedMessage id="AuthenticationPage.sendingEmail" />
            ) : (
              <FormattedMessage id="AuthenticationPage.resendEmail" values={{ resendEmailLink }} />
            )}
          </TypographyWrapper>
        </p>
        <p className={css.modalHelperText}>
          <TypographyWrapper
            variant="body2"
            typographyOverrides={{ style: { color: defaultTreetStyles.gray40 } }}
          >
            <FormattedMessage id="AuthenticationPage.fixEmail" values={{ fixEmailLink }} />
          </TypographyWrapper>
        </p>
      </div>
    </>
  );

  const schemaTitle = isLogin
    ? intl.formatMessage(
        { id: 'AuthenticationPage.schemaTitleLogin' },
        { siteTitle: treetShopName }
      )
    : intl.formatMessage(
        { id: 'AuthenticationPage.schemaTitleSignup' },
        { siteTitle: treetShopName }
      );

  const topbarClasses = classNames({
    [css.hideOnMobile]: showEmailVerification,
  });

  const formContent = (
    <AuthenticationForm
      bannerText={bannerText}
      from={from}
      tab={tab}
      handleOpenTermsOfService={() => setTosModalOpen(true)}
      handleOpenPrivacyPolicy={() => setPrivacyModalOpen(true)}
      loginTabProperties={{
        linkProps: {
          name: 'LoginPage',
          to: fromState,
        },
      }}
      signupTabProperties={{
        linkProps: {
          name: 'SignupPage',
          to: fromState,
        },
      }}
    />
  );

  return (
    <>
      <AuthenticationContentWrapper
        title={schemaTitle}
        schema={{
          '@context': 'http://schema.org',
          '@type': 'WebPage',
          name: schemaTitle,
        }}
        topbarClassName={topbarClasses}
      >
        {showEmailVerification ? emailVerificationContent : formContent}
      </AuthenticationContentWrapper>
      <Modal
        id="AuthenticationPage.tos"
        isOpen={tosModalOpen}
        onClose={() => setTosModalOpen(false)}
        usePortal
        onManageDisableScrolling={onManageDisableScrolling}
      >
        <div className={css.termsWrapper}>
          <h2 className={css.termsHeading}>
            <FormattedMessage id="AuthenticationPage.termsHeading" />
          </h2>
          {customComponents?.termsOfServiceComponent || <TermsOfService />}
        </div>
      </Modal>
      <Modal
        id="AuthenticationPage.privacy"
        isOpen={privacyModalOpen}
        onClose={() => setPrivacyModalOpen(false)}
        usePortal
        onManageDisableScrolling={onManageDisableScrolling}
      >
        <div className={css.termsWrapper}>
          <h2 className={css.termsHeading}>Privacy Policy</h2>
          {customComponents?.privacyPolicyComponent || <PrivacyPolicy />}
        </div>
      </Modal>
    </>
  );
};

AuthenticationPageComponent.defaultProps = {
  currentUser: null,
  tab: 'signup',
  sendVerificationEmailError: null,
};

const { bool, func, object, oneOf, shape } = PropTypes;

AuthenticationPageComponent.propTypes = {
  currentUser: propTypes.currentUser,
  isAuthenticated: bool.isRequired,
  tab: oneOf(['login', 'signup']),

  sendVerificationEmailInProgress: bool.isRequired,
  sendVerificationEmailError: propTypes.error,
  onResendVerificationEmail: func.isRequired,
  onManageDisableScrolling: func.isRequired,

  // from withRouter
  location: shape({ state: object }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = (state) => {
  const { isAuthenticated } = state.Auth;
  const { currentUser, sendVerificationEmailInProgress, sendVerificationEmailError } = state.user;
  return {
    authInProgress: authenticationInProgress(state),
    currentUser,
    isAuthenticated,
    sendVerificationEmailInProgress,
    sendVerificationEmailError,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onResendVerificationEmail: () => dispatch(sendVerificationEmail()),
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const AuthenticationPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(AuthenticationPageComponent);

export default AuthenticationPage;
